import "core-js/modules/es.array.for-each";import "core-js/modules/es.number.constructor";import "core-js/modules/web.dom-collections.for-each"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import qr from './qr/index';
/* eslint-disable */
export default {
  props: {
    val: {
      type: String,
      required: true },

    size: {
      type: Number,
      "default": 100 },

    // 'L', 'M', 'Q', 'H'
    level: String,
    bgColor: {
      type: String,
      "default": '#FFFFFF' },

    fgColor: {
      type: String,
      "default": '#000000' } },


  beforeUpdate: function beforeUpdate() {
    this.update();
  },
  mounted: function mounted() {
    this.update();
  },
  methods: {
    getUrl: function getUrl(url) {
      this.$emit('getUrl', url);
    },
    update: function update() {var
      size = this.size;var
      bgColor = this.bgColor;var
      fgColor = this.fgColor;
      var $qr = this.$refs.qr;

      var qrcode = qr(this.val);

      var ctx = $qr.getContext('2d');
      var cells = qrcode.modules;
      var tileW = size / cells.length;
      var tileH = size / cells.length;
      var scale = (window.devicePixelRatio || 1) / getBackingStorePixelRatio(ctx);

      $qr.height = $qr.width = size * scale;
      ctx.scale(scale, scale);

      cells.forEach(function (row, rdx) {
        row.forEach(function (cell, cdx) {
          ctx.fillStyle = cell ? fgColor : bgColor;
          var w = Math.ceil((cdx + 1) * tileW) - Math.floor(cdx * tileW);
          var h = Math.ceil((rdx + 1) * tileH) - Math.floor(rdx * tileH);
          ctx.fillRect(Math.round(cdx * tileW), Math.round(rdx * tileH), w, h);
        });
      });
      this.getUrl($qr.toDataURL('image/jpg'));
    } } };



function getBackingStorePixelRatio(ctx) {
  return (
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1);

}