var mode = require('./mode');
/* eslint-disable */
function QR8bitByte(data) {
  this.mode = mode.MODE_8BIT_BYTE;
  this.data = data;
}

QR8bitByte.prototype = {

  getLength: function getLength(buffer) {
    return this.data.length;
  },

  write: function write(buffer) {
    for (var i = 0; i < this.data.length; i++) {
      // not JIS ...
      buffer.put(this.data.charCodeAt(i), 8);
    }
  } };


module.exports = QR8bitByte;