var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("canvas", {
      ref: "qr",
      staticClass: "can",
      style: { height: _vm.size + "px", width: _vm.size + "px" },
      attrs: { height: _vm.size, width: _vm.size, val: _vm.val }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }